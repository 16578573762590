.container {
    padding: 0 2.5rem;

    @include respond(large) {
        padding: 0 5rem;
    }

    @include respond(larger) {
        padding: 0 3rem;
    }
}

.overflow-hidden {
    overflow: hidden !important;
}
