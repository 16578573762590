.about {
    padding: 4rem 0 10rem;

    @include respond(larger) {
        padding: 6rem 0 10rem;
    }

    &_contact-links {
        font-size: 1.8rem;

        &:hover {
            opacity: 0.9;
            animation: pulse;
            animation-duration: 1.5s;
        }
    }

    &_my-image {
        border-radius: 0.5rem;
        height: 45rem;
        object-fit: cover;
        object-position: center;
        margin-bottom: 5rem;

        @include respond(large) {
            object-position: center 50%;
            margin-bottom: 8rem;
        }
    }

    &_description {
        @include respond(larger) {
            padding: 0 5rem;
        }
    }
}
